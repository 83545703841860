<template>
    <div class="layout-sidebar" @click="onSidebarClick">
        <AppMenu :model="menu_ame" @menuitem-click="onMenuItemClick" />
    </div>
</template>
<script>

import AppMenu from "../AppMenu.vue";

export default {
    emits: ["change-theme"],
    data() {
        return {
            menu_ame: [

                {
                    "label": "Gestão de Faturas",
                    "icon": "pi pi-fw pi-home",
                    "to": "/dashboard",
                    "items": [
                        {
                            "label": "Home",
                            "icon": "pi pi-fw pi-home",
                            "to": "/dashboard"
                        }
                    ]
                },
                {
                    "label": "Gestão  de Faturas (Modelo 66)",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [
                    {
                            "label": "Implantar XML",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/xml/implantar-xml"
                        },
                        {
                            "label": "Faturas Implantadas (SGE)",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/implantadas"
                        },
                        {
                            "label": "XML Capturados",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/xml/capturados"
                        },
                      
                        {
                            "label": "Processar BRNotas Lote",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/xml/processar-lote"
                        },
                     
                        {
                            "label": "Relatórios",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/relatorios/envio"
                        }
                    ]
                },
                {
                    "label": "Gestão  de Faturas Modelo (6)",
                    "icon": "pi pi-fw pi-sitemap",
                    "items": [
                        {
                            "label": "Faturas Implantadas (SGE)",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/implantadas"
                        },
                        {
                            "label": "Enviar Lotes",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/faturas/enviar"
                        }
                    ]
                },
                {
                    "label": "Configurações",
                    "items": [
                        {
                            "label": "Empresas",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/empresas"
                        },
                        {
                            "label": "Status de Erro",
                            "icon": "pi pi-fw pi-file-pdf",
                            "to": "/status"
                        },
                        {
                            "label": "Usuários",
                            "icon": "pi pi-fw pi-user-edit",
                            "to": "/usuarios"
                        }
                    ]
                },
                {
                    "items": [
                        {
                            "label": "Sair",
                            "icon": "pi pi-fw pi-exclamation-circle",
                            command: () => {
                                this.axios
                                    .post("/auth/logout")
                                    .then(() => {
                                        localStorage.removeItem("token");
                                        localStorage.removeItem("user");
                                        this.$router.push("/");
                                    })
                                    .catch(() => {

                                    });

                            },

                        }
                    ]
                }
            ],
        }
    },
    components: {
        AppMenu: AppMenu
    },
}
</script>